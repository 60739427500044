<template>
  <div>
    <el-tabs v-model="activeName">
      <el-button type="warning" @click="back()" size="small">《 返回</el-button>
      <el-button
        type="danger"
        @click="rejectRouteFn()"
        size="small"
        style="margin-left: 200px;"
        v-if="tripGuide.updated&&tripGuide.state==2"
      >退回为草稿</el-button>
      <el-button
        type="success"
        @click="authRouteFn()"
        size="small"
        style="margin-left: 40px;"
        v-if="tripGuide.updated&&tripGuide.state==2"
      >检查完成</el-button>
      <el-tab-pane label="攻略" name="1" v-if="tripGuide">
        <el-row :gutter="100" style="margin:50px 0">
          <el-col :span="12">
            <table>
              <thead>
                <td width="120px">名称</td>
                <td>内容</td>
              </thead>
              <tr>
                <td>名称</td>
                <td>{{tripGuide.name}}</td>
              </tr>
              <tr>
                <td>ID</td>
                <td>{{tripGuide.id}}</td>
              </tr>
              <tr>
                <td>状态</td>
                <td>{{tripGuide.state}}</td>
              </tr>
              <tr>
                <td>作者</td>
                <td>{{tripGuide.idUser}} . {{ tripGuide.user.nickName }}</td>
              </tr>
              <tr>
                <td>点赞</td>
                <td>{{tripGuide.thumbs}}</td>
              </tr>
            </table>

            <div style="font-size: 12px; margin-top:40px" v-if="sights.length>0">
              <table style="padding: 10px 0; border-top:1px dashed #555">
                <tr v-for="(sight,i) in sights" :key="i">
                  <td>{{ sight.name }}</td>
                </tr>
              </table>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="coverimg">
              <img :src="'https://server.solar960.com'+tripGuide.img" width="50%" alt />
            </div>
            <div v-html="tripGuide.intro" style="padding: 0 30px"></div>
          </el-col>
        </el-row>
      </el-tab-pane>
      <!-- <el-tab-pane label="地图" name="2">
        <mapview :positions="sights" v-if="activeName=='2'" />
      </el-tab-pane> -->
      <el-tab-pane :label="commentCount==0?'评论':'评论('+commentCount+')'" name="3">
        <commentpage :custId="'tripGuide'" @count="setCommentCount" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import commentpage from "@/components/CommentPage";
// import mapview from "../components/MapView";
import { adminGetTripGuide, authRouteAndGuide } from "@/api/api";

export default {
  components: {
    commentpage,
    // mapview
  },
  data() {
    return {
      activeName: "1",
      id: JSON.parse(this.$route.query.id),
      tripGuide: {},
      mapShow: false,
      comments: [],
      sights: [],
      commentCount: 0
    };
  },
  created() {
    this.adminGetTripGuide();
  },

  methods: {
    // 获取景区详情
    adminGetTripGuide() {
      const that = this;
      adminGetTripGuide({
        id: that.id
      }).then(res => {
        if (res.result) {
          that.tripGuide = res.data.tripGuide;
          that.sights = res.data.sights;
        }
      });
    },
    back() {
      this.$router.go(-1);
    },
    rejectRouteFn() {
      const that = this;
      this.$prompt("请输入原因", "撤回为草稿", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^\S{1,}$/,
        inputErrorMessage: "请输入原因",
        closeOnClickModal: false
      })
        .then(({ value }) => {
          authRouteAndGuide({
            id: that.tripGuide.id,
            type: "reject",
            value: value,
            model: "guide"
          }).then(res => {
            if (res.result) {
              that.$message({
                type: "warning",
                message: "已退回"
              });
              let getFirstAuth = that.$store.getters.getFirstAuth;
              getFirstAuth.tripGuide = getFirstAuth.tripGuide - 1;
              that.$store.dispatch("setFirstAuth", getFirstAuth);
              that.back();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消"
          });
        });
    },
    authRouteFn() {
      const that = this;
      this.$confirm("检查通过？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          authRouteAndGuide({
            id: that.tripGuide.id,
            type: "auth",
            model: "guide"
          }).then(res => {
            if (res.result) {
              that.$message({
                type: "success",
                message: "已通过"
              });

              let getFirstAuth = that.$store.getters.getFirstAuth;
              getFirstAuth.tripGuide = getFirstAuth.tripGuide - 1;
              that.$store.dispatch("setFirstAuth", getFirstAuth);
              that.back();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消更改"
          });
        });
    },
    setCommentCount(e) {
      this.commentCount = e;
    }
  }
};
</script>

<style scoped>
.coverimg {
  text-align: center;
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px dashed #aaa;
}
</style>